import { FC, memo } from 'react';
import { MessageWrapper } from './styles';

const greetings_title = 'Hello there 👋';
const greetings_descriptions =
  'Welcome to SignLine. We are dedicated only to the profoundly Deaf Segment of the Society.';
const unavailable_text =
  'Dear Customer, thank you for reaching out. We are currently unavailable. You can access our Sign Language service from 10:00 AM to 7:00 PM.';

export const Message: FC<{ isUnavailable?: boolean }> = memo(({ isUnavailable }) => {
  return (
    <MessageWrapper>
      <h4>{greetings_title}</h4>
      <p>{isUnavailable ? greetings_descriptions : unavailable_text}</p>
    </MessageWrapper>
  );
});
